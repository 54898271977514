<template>
    <section class="page-giftcards-types">
        <div class="page-giftcards-types__header">
            <div class="page-giftcards-types__header-title">
                СЕРТИФИКАТ ПАЙ В ПОДАРОК
            </div>
            <div class="page-giftcards-types__header-subtitle">
                Выберите, какой подарочный сертификат вы хотите. Оба варианта бессрочные
            </div>
        </div>
        <div class="page-giftcards-types__options">
            <a class="page-giftcards-types__options-item"
                v-for="(item, i) in items"
                v-bind:key="i"
                v-bind:href="item.href"
                v-bind:style="{ backgroundImage: item.background }"
                >
                <div class="page-giftcards-types__options-item-title"
                    v-text="item.title"
                />
                <div class="page-giftcards-types__options-item-subtitle"
                    v-text="item.subtitle"
                />
                <div class="page-giftcards-types__options-item-placeholder" />
                <div class="page-giftcards-types__options-item-button">
                    Выбрать
                </div>
            </a>
        </div>
    </section>
</template>

<script>
import utils from '~/utils';

export default {
    name: 'page-giftcards-types',
    inject: [ 'staticPathRoot' ],
    props: {
        urlReal: {
            type: String,
        },
        urlUnreal: {
            type: String,
        },
    },
    data: () => ({
        isDesktop: window.innerWidth >= 1024,
    }),
    computed: {
        items() {
            const resize = utils.common.getResize({ width: this.isDesktop ? 50 : 100 });
            return [
                {
                    href: this.urlReal,
                    title: 'Бумажный',
                    subtitle: 'Бесплатная доставка',
                    background: this.isDesktop
                        ? `url(${this.staticPathRoot + utils.common.injectResize('/images/gift-cards/background-paper-desktop-v3.png', resize)})`
                        : `url(${this.staticPathRoot + utils.common.injectResize('/images/gift-cards/background-paper-mobile-v3.png', resize)})`,
                },
                {
                    href: this.urlUnreal,
                    title: 'Электронный',
                    subtitle: 'Доставим на электронную почту',
                    background: this.isDesktop
                        ? `url(${this.staticPathRoot + utils.common.injectResize('/images/gift-cards/background-digital-desktop-v3.png', resize)})`
                        : `url(${this.staticPathRoot + utils.common.injectResize('/images/gift-cards/background-digital-mobile-v3.png', resize)})`,
                },
            ];
        },
    },
    methods: {
        onResize() {
            this.isDesktop = window.innerWidth >= 1024;
        },
    },
    beforeMount() {
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-giftcards-types {
    .container();
    .typography-body-md();

    flex: 1 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    background: @color-gray-main;
    &__header {
        flex: 0 0 auto;
        padding: 40px 20px 64px;
        margin-bottom: 1px;

        text-align: center;
        &-title {
            .typography-display-md();

            margin-bottom: 8px;

            text-transform: uppercase;
        }
    }
    &__options {
        flex: 0 0 auto;
        display: flex;
        width: 100%;
        border-top: 1px solid @color-gray-lightest;
        &-item {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 50%;
            padding: 40px 20px 80px;
            aspect-ratio: 3088 / 2500;

            color: @color-gray-darkest;
            text-decoration: none;

            background-color: @color-gray-main;
            background-position: center;
            background-size: cover;
            &:hover &-button {
                color: @color-gray-lightest;

                background-color: @color-gray-darkest;
            }
            &:nth-child(2n) {
                border-left: 1px solid @color-gray-lightest;
            }
            &-title {
                .typography-display-md();

                margin-bottom: 8px;
            }
            &-subtitle {
                margin-top: 0;
                margin-bottom: 40px;
            }
            &-placeholder {
                width: 193px;
                aspect-ratio: 193/272;
                margin: auto;
                // opacity: 0;
                pointer-events: none;
            }
            &-button {
                height: 48px;
                width: 500px;
                max-width: 100%;
                border: 1px solid @color-gray-darkest;
                margin-top: 40px;

                line-height: 48px;
                text-align: center;
                text-transform: uppercase;

                background-color: transparent;

                transition: all @duration-fast @easing-default;
            }
        }
    }
    @media @media-md-down {
        &__header {
            padding : 24px 16px 40px;

            text-align: left;
            &-title {
                .typography-display-sm();

                margin-bottom: 4px;
            }
        }
        &__options {
            flex-direction: column;
            &-item {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: min-content min-content min-content;
                column-gap: 16px;
                align-items: center;
                align-content: center;
                justify-items: center;

                width: 100%;
                padding: 24px 32px 40px 16px;
                aspect-ratio: 2548 / 1801;
                &:nth-child(2n) {
                    border-left: none;
                    border-top: 1px solid @color-gray-lightest;
                }
                &-title {
                    .typography-display-sm();

                    grid-column: 1;
                    grid-row: 1;
                }
                &-subtitle {
                    grid-column: 1;
                    grid-row: 2;
                }
                &-placeholder {
                    grid-column: 2;
                    grid-row: ~'1 / 4';
                    width: 135px;
                    display: none;
                }
                &-button {
                    grid-column: 1;
                    grid-row: 3;
                    width: 140px;
                }
            }
        }
    }
    @media @media-sm-down {
        &__options {
            &-item {
                justify-items: flex-start;
                &-placeholder {
                    justify-self: flex-end;
                }
                &-button {
                    align-self: flex-end;
                }
            }
        }
    }
}
</style>
