<template>
    <section class="page-giftcards-items">
        <div class="page-giftcards-items__header">
            <div class="page-giftcards-items__header-title"
                v-text="title"
            />
            <div class="page-giftcards-items__header-subtitle"
                v-text="subtitle"
            />
        </div>
        <page-giftcards-items-list
            v-if="step === 'list'"
            v-on:step-change="updateStep"
        />
        <page-giftcards-items-form
            v-else
            v-bind:selected-item="selectedItem"
            v-on:step-change="updateStep"
        />
    </section>
</template>

<script>
export default {
    name: 'page-giftcards-items',
    props: {
        type: {
            type: String,
        },
        requestUrl: {
            type: String,
        },
    },
    provide() {
        return {
            type: this.type,
            requestUrl: this.requestUrl,
        };
    },
    data: () => ({
        step: 'list',
        selectedItem: null,
    }),
    computed: {
        title() {
            return this.step === 'list'
                ? `Выберите номинал ${this.type === 'digital' ? 'электронного' : 'бумажного'} сертификата`
                : 'Кто счастливый получатель?';
        },
        subtitle() {
            if (this.step === 'list') {
                if (this.type === 'digital') {
                    return `Сертификат можно использовать как при оплате на сайте, так и в офлайн магазинах. Сертификат бессрочный, а остаток несгораемый. Сертификат отправляется автоматически в течение нескольких минут после оплаты.`;
                } else {
                    return `Сертификат можно использовать как при оплате на сайте, так и в офлайн магазинах. Сертификат бессрочный, а остаток несгораемый.  Сертификат доставим бесплатно курьерской службой по указанному адресу.`;
                }
            } else {
                return 'Расскажите кому и от кого этот подарок';
            }
        },
    },
    methods: {
        updateStep(data) {
            this.step = data.newStep;
            this.selectedItem = data.item;
        },
        checkHash() {
            let hash = window.location.hash;
            if (!hash) {
                // window.location.hash = this.step;
                // window.history.pushState('', '', window.location.href + '#' + this.step);
                this.step = 'list';
                return;
            }
            hash = hash.substring(1);
            if (hash === 'list' || (hash === 'form' && !!this.selectedItem)) {
                this.step = hash;
            }
        },
    },
    beforeMount() {
        window.location.hash = this.step;
        window.addEventListener('hashchange', this.checkHash);
    },
    beforeDestroy() {
        window.removeEventListener('hashchange', this.checkHash);
    },
    watch: {
        step: {
            immediate: true,
            handler() {
                window.scrollTo(0, 0);
                window.location.hash = this.step;
                // this.checkHash();
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-giftcards-items {
    .container();
    .typography-body-md();
    .mixin-full-height();

    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding-bottom: 120px;

    background: @color-gray-main;
    &__header {
        padding: 40px 20px 64px;

        text-align: center;
        &-title {
            .typography-display-md();

            margin-bottom: 8px;

            text-transform: uppercase;
        }
        &-subtitle {
            max-width: 1040px;
            margin: 0 auto;
        }
    }
    @media @media-md-down {
        padding-bottom: 0;
    }
}
</style>
